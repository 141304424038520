import { addSnack, deleteTask, deleteTaskSelector, tasks, tasksSelector, toggleCreateTaskModal, toggleEditTaskModal, updateTask, updateTasks } from "features";
import { useAppDispatch, useAppSelector } from "store";
import { TTask } from "types/types";
import styles from "./settingsTask.module.sass"

import CloseIcon from "./assets/CloseIcon";
import ResetIcon from "./assets/ResetIcon";
import DoneIcon from "./assets/DoneIcon";
import EditIcon from "./assets/EditIcon";
import { useEffect, useId, useState } from "react";
import classNames from "classnames";

interface ISettingsTaskProps {
  position: { left: number, top: number };
  resetPosition: () => void;
  activeTask: TTask | null;
}

const noop = () => {};

const SettingsTask = ({ position, resetPosition, activeTask }: ISettingsTaskProps) => {
  const dispatch = useAppDispatch();
  const snackId = useId();
  const {fetching, success} = useAppSelector(deleteTaskSelector);
  const {result} = useAppSelector(tasksSelector);

  const [isDisabled, setDisabled] = useState(false)


  const toggleTask = (isOpen: boolean) => {
    if(activeTask?.id) {
      dispatch(updateTask({ isOpen, id: activeTask?.id }))
      resetPosition()
      dispatch(updateTasks(result?.map((task) => task.id === activeTask?.id ? {...task, isOpen: !task.isOpen } : task)))
      dispatch(addSnack({
        variant: "success",
        text: activeTask?.isOpen ? "Задача завершена" : "Задача открыта",
        id: snackId,
      }));
    }
  }

  const handleEditTask = () => {
    dispatch(toggleEditTaskModal(activeTask))
    dispatch(toggleCreateTaskModal());
    resetPosition()
  }

  const handleDeleteTask = () => {
    if(activeTask?.id) {
      setDisabled(true);
      dispatch(deleteTask({ id: activeTask?.id }))
    }
  }

  useEffect(() => {
    if (!fetching && success) {
      dispatch(addSnack({
        variant: "success",
        text: "Задача удалена",
        id: snackId,
      }));
      resetPosition();
      dispatch(updateTasks(result?.filter(({id}) => id !== activeTask?.id)))
    }
  }, [fetching, success])

  return (
    <>
      <div className={styles.eventSettings} style={{
        left: `${position.left}px`,
        top: `${position.top}px`,
      }}>
        <div
          className={classNames(styles.option, {[styles.disabled]: isDisabled})}
          onClick={() => isDisabled ? noop : toggleTask(!activeTask?.isOpen)}
        >
          {activeTask?.isOpen ? <DoneIcon /> : <ResetIcon />}
          {activeTask?.isOpen ? "Завершить" : "Открыть задачу"}
        </div>
        <div
          className={classNames(styles.option, {[styles.disabled]: isDisabled})}
          onClick={isDisabled ? noop : handleEditTask}
        >
          <EditIcon className={styles.editTaskIcon} />
          Редактировать задачу
        </div>
        <div
           className={classNames(styles.option, {[styles.disabled]: isDisabled})}
          onClick={isDisabled ? noop : handleDeleteTask}
        >
          <CloseIcon />
          Удалить задачу
        </div>
      </div>
      {!!position.left && <div className={styles.overlay} onClick={resetPosition} />}
    </>
  )
}

export default SettingsTask;