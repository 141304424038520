const DoneIcon = ({ className }: { className?: string }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g clipPath="url(#clip0_6257_37941)">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.773 2.55578C10.9136 2.69642 10.9926 2.88715 10.9926 3.08603C10.9926 3.2849 10.9136 3.47563 10.773 3.61628L5.15151 9.23778C5.07722 9.31208 4.98902 9.37103 4.89195 9.41124C4.79488 9.45146 4.69084 9.47216 4.58576 9.47216C4.48069 9.47216 4.37665 9.45146 4.27957 9.41124C4.1825 9.37103 4.0943 9.31208 4.02001 9.23778L1.22701 6.44528C1.15538 6.37609 1.09824 6.29333 1.05894 6.20183C1.01963 6.11033 0.998941 6.01191 0.998075 5.91233C0.99721 5.81274 1.01619 5.71398 1.0539 5.62181C1.09161 5.52964 1.1473 5.4459 1.21772 5.37548C1.28814 5.30506 1.37188 5.24937 1.46405 5.21166C1.55622 5.17395 1.65498 5.15497 1.75456 5.15584C1.85415 5.1567 1.95256 5.17739 2.04407 5.2167C2.13557 5.25601 2.21833 5.31314 2.28751 5.38478L4.58551 7.68278L9.71201 2.55578C9.78166 2.48608 9.86436 2.43079 9.95539 2.39307C10.0464 2.35535 10.144 2.33594 10.2425 2.33594C10.341 2.33594 10.4386 2.35535 10.5296 2.39307C10.6207 2.43079 10.7034 2.48608 10.773 2.55578Z" fill="#009C3E" />
    </g>
    <defs>
      <clipPath id="clip0_6257_37941">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>

);

export default DoneIcon;