import React from "react";

const MainPage = React.lazy(() => import(
  /* webpackChunkName: "page-main" */
  "../components/pages/Main/Main"
));

type TRoute = {
  path: string;
  Component: React.LazyExoticComponent<() => JSX.Element>;
};

export const routes: TRoute[] = [
  {
    path: "/",
    Component: MainPage,
  },
];