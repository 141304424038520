import { createSlice } from "@reduxjs/toolkit";
import { WritableDraft } from "immer";
import { TLoginInfo } from "types/types";
import {
  ICreateTaskRequest,
  ICreateTaskResponse,
  IDeleteTaskRequest,
  IDeleteTaskResponse,
  IGetTasksRequest,
  IGetTasksResponse,
  ILoginRequest,
  ILoginResponse,
  IUpdateTaskRequest,
  IUpdateTaskResponse,
} from "../../interfaces/interfaces";
import { PlannerState } from "../../types/storeTypes";

import { initDetail, initDto, onFulfilledDataReducer, onFulfilledReducer, onPendingReducer, onRejectedReducer } from "../reducers";
import { createShopThunk } from "../thunks";


const initialState: PlannerState = {
  user: { ...initDetail },
  createTask: { ...initDetail },
  tasks: { ...initDetail },
  updateTask: { ...initDetail },
  deleteTask: { ...initDetail },
};

type StateDraft = WritableDraft<PlannerState>;


// =-=-=-=-=-=-=-=-=-=-=-=-= THUNKS =-=-=-=-=-=-=-=-=-=-=-=-=

export const user = createShopThunk<
  ILoginResponse,
  ILoginRequest
>("user");

export const createTask = createShopThunk<
  ICreateTaskResponse,
  ICreateTaskRequest
>("createTask");

export const tasks = createShopThunk<
  IGetTasksResponse,
  IGetTasksRequest
>("tasks");

export const updateTask = createShopThunk<
  IUpdateTaskResponse,
  IUpdateTaskRequest
>("updateTask");

export const deleteTask = createShopThunk<
  IDeleteTaskResponse,
  IDeleteTaskRequest
>("deleteTask");

// =-=-=-=-=-=-=-=-=-=-=-=-= SLICE =-=-=-=-=-=-=-=-=-=-=-=-=

export const generalSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    resetCreateTask(state) {
      state.createTask = { ...initDetail }
    },
    updateTasks(state, action) {
      state.tasks.result = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(user.pending, onPendingReducer<StateDraft>("user"))
      .addCase(user.rejected, onRejectedReducer<StateDraft>("user"))
      .addCase(user.fulfilled, onFulfilledReducer<StateDraft>("user"))

      .addCase(createTask.pending, onPendingReducer<StateDraft>("createTask"))
      .addCase(createTask.rejected, onRejectedReducer<StateDraft>("createTask"))
      .addCase(createTask.fulfilled, onFulfilledReducer<StateDraft>("createTask", (action, state) => {
        if (action.payload && state.tasks.result) {
          state.tasks.result = [...state.tasks.result, action.payload]
        }
      }))

      .addCase(updateTask.pending, onPendingReducer<StateDraft>("updateTask"))
      .addCase(updateTask.rejected, onRejectedReducer<StateDraft>("updateTask"))
      .addCase(updateTask.fulfilled, onFulfilledReducer<StateDraft>("updateTask"))

      .addCase(tasks.pending, onPendingReducer<StateDraft>("tasks"))
      .addCase(tasks.rejected, onRejectedReducer<StateDraft>("tasks"))
      .addCase(tasks.fulfilled, onFulfilledReducer<StateDraft>("tasks"))

      .addCase(deleteTask.pending, onPendingReducer<StateDraft>("deleteTask"))
      .addCase(deleteTask.rejected, onRejectedReducer<StateDraft>("deleteTask"))
      .addCase(deleteTask.fulfilled, onFulfilledReducer<StateDraft>("deleteTask"))
    // .addCase(userLogin.fulfilled, onFulfilledDataReducer<StateDraft, ILoginResponse, TLoginInfo>("user"))

    // .addCase(search.pending, onPendingReducer<ShopStateDraft>("search"))
    // .addCase(search.rejected, onRejectedReducer<ShopStateDraft>("search"))
    // .addCase(search.fulfilled, onFulfilledReducer<ShopStateDraft>("search", (action, state) => {
    //   const { products, message, pageCount, total_products } = action.payload ?? {}
    //   state.search.result = products ?? null
    //   state.search.message = message ?? ""
    //   if (pageCount && state.search.dto) {
    //     state.search.dto.totalPages = pageCount
    //   }
    //   if (total_products && state.search.dto) {
    //     state.search.dto.totalProducts = total_products
    //   }

    // }))
  }
});

const { actions, reducer } = generalSlice;

export const {
  resetCreateTask,
  updateTasks,
} = actions;

export default reducer;
