import { useField } from "formik";
import { FC } from "react";
import styles from "./input.module.sass";

interface IInputProps {
  label?: string;
  name: string;
}

const Input: FC<IInputProps & React.InputHTMLAttributes<HTMLInputElement>> = ({ label, name, ...props }) => {
  const [field, { touched, error }] = useField(name);

  return (
    <>
      <input className={styles.input} {...props} {...field} />
      {error && touched && (
        <p className={styles.textError}>
          {error}
        </p>
      )}
    </>
  )
}

export default Input;
