import { ProgressBar } from "react-bootstrap";
import styles from "./header.module.sass"
import SettingsIcon from "./SettingsIcon";

const Header = () => {
  return (
    <header className={styles.header}>
      {/* <div className={styles.progressBarContainer}>
        <div className={styles.progressBarHead}>
          <span className={styles.period}>{"На сегодня >"}</span>
          <span className={styles.progress}>5/9</span>
        </div>
        <ProgressBar now={60} className={styles.progressBar} />
      </div> */}
      <div className={styles.logoContainer}>
        Planner
      </div>
      <SettingsIcon className={styles.settingsIcon} />
    </header>
  )
}

export default Header;