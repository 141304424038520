import { createAsyncThunk } from "@reduxjs/toolkit";
import service from "../services/service";

export const loginUserThunk = createAsyncThunk(
  'auth/login',
  async (_, thunkAPI) => {
    try {
      const response = await service.user({userData: window.Telegram.WebApp.initData});
        console.log(response.data);

      if (response.data.token) {
        localStorage.setItem('token', response.data.token)
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue("Ошибка при логине");
    }
  }
);
