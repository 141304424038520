import { ICreateTaskRequest, IDeleteTaskRequest, IGetTasksRequest, ILoginRequest, IUpdateTaskRequest } from "interfaces";
import { axiosExchange } from "./axios";

export enum ENDPOINTS {
  user = "auth/login",
  createTask = "tasks",
  tasks = "tasks",
  updateTask = "tasks",
  deleteTask = "tasks",
}

const user = async ({ ...request }: ILoginRequest) => {
  return axiosExchange.post(
    `/${ENDPOINTS.user}`,
    request
  );
}

const createTask = async ({ ...request }: ICreateTaskRequest) => {
  return axiosExchange.post(
    `/${ENDPOINTS.createTask}/`,
    request,
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
        'Content-Type': 'application/json',
        'Time-Zone-Offset': -new Date().getTimezoneOffset() / 60,
      }
    }
  );
}

const updateTask = async ({ id, ...request }: IUpdateTaskRequest) => {
  return axiosExchange.patch(
    `/${ENDPOINTS.updateTask}/${id}`,
    request,
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
        'Content-Type': 'application/json',
        'Time-Zone-Offset': -new Date().getTimezoneOffset() / 60,
      }
    }
  );
}


const deleteTask = async ({id}: IDeleteTaskRequest) => {
  return axiosExchange.delete(
    `/${ENDPOINTS.deleteTask}/${id}`,
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
        'Content-Type': 'application/json',
      }
    }
  );
}

const tasks = async ({ dateStart, dateEnd }: IGetTasksRequest) => {
  const filteredParams = Object.entries({ dateStart, dateEnd })
    .filter(([_, value]) => value != null)
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  const queryString = new URLSearchParams(filteredParams).toString();

  return axiosExchange.get(
    `/${ENDPOINTS.tasks}/?${queryString}`,
    {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("token")}`,
      }
    }
  );
}

const service = {
  user,
  createTask,
  tasks,
  updateTask,
  deleteTask,
};

export default service;