import { Modal as ModalBootstrap } from 'react-bootstrap';
import styles from "./modal.module.sass"

interface IModalProps {
  isShow: boolean;
  onHide: () => void;
  children: string | JSX.Element;
  title?: string;
}

const Modal = ({ isShow, onHide, children, title }: IModalProps) => {
  return (
    <ModalBootstrap show={isShow} onHide={onHide} className={styles.modal}>
      <ModalBootstrap.Header className={styles.modalHeader}>
        {!!title && <ModalBootstrap.Title className={styles.title}>{title}</ModalBootstrap.Title>}
        <button className={styles.closeModal} onClick={onHide} />
      </ModalBootstrap.Header>
      <ModalBootstrap.Body className={styles.modalBody}>{children}</ModalBootstrap.Body>
    </ModalBootstrap>
  )
}

export default Modal