import { useField } from "formik";
import { FC } from "react";
import styles from "./textArea.module.sass";

interface ITextAreaProps {
  label?: string;
  name: string;
}

const TextArea: FC<ITextAreaProps & React.TextareaHTMLAttributes<HTMLTextAreaElement>> = ({ label, name, ...props }) => {
  const [field] = useField(name);

  return (
      <textarea className={styles.input} {...props} {...field} />
  )
}

export default TextArea;
