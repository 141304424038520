
import { PayloadAction } from "@reduxjs/toolkit";
import { FetchStatus, IResponseSuccess } from "../interfaces";

export const initFetch: FetchStatus = {
  fetching: false,
  success: false,
  message: null
}

type ActionError = {
  message?: string
}

type StateType = {
  [k: string]: FetchStatus & any
}

export const initDetail = {
  result: null,
  ...initFetch
};

export const initArray = {
  result: [],
  ...initFetch
};

export const initKeyValue = {
  result: {},
  ...initFetch
};

export const initDto = {
  page: 1,
  perPage: 25,
  totalPages: null,
  filters: null
}

// Универсальный редюсер для pending
// S - тип черновика стора (StateDraft)
// key - здесь и далее ключ данной entity в state
// callback - здесь и далее функция для доп.операций после смены state, например запись в localStorage

export const onPendingReducer = <S extends StateType,>(
  key: keyof S,
  callback?: (action: PayloadAction<any>
  ) => void) => (state: S, action: PayloadAction<any>) => {

    state[key].fetching = true;
    state[key].success = false;

    if (callback) callback(action);
  }


// Универсальный редюсер для rejected
// S - тип черновика стора (StateDraft)

export const onRejectedReducer = <S extends StateType,>(
  key: keyof S,
  callback?: (action: PayloadAction<any>) => void
) => (state: S, action: PayloadAction<any, any, any, any>) => {

  state[key].fetching = false;
  state[key].success = false;
  state[key].message = key === "search" ? "error search" : getError(action);
  console.log("Rejected action:", action);
  if (callback) callback(action);
};

const getError = (action: PayloadAction<any, any, any, ActionError>) => {
  if (typeof action.payload === "string") {
    return action.payload;
  }
  if (action.error.message) {
    return action.error.message
  }
  if (action.payload?.message) {
    return action.payload.message;
  }
  if ("message" in action.payload && typeof action.payload.message === "string") {
    return action.payload.message as string;
  }

  return `Неизвестная ошибка при вызове метода ${action.type.split('/')[1]}`;
}

export const onFulfilledReducer = <S extends StateType,>(
  key: keyof S,
  callback?: (action: PayloadAction<any>, state: S
  ) => void) => (state: S, action: PayloadAction<any>) => {
    const payloadData = action.payload?.data || action.payload;

    state[key].fetching = false;
    state[key].success = true;
    state[key].message = payloadData?.message || "";
    state[key].result = payloadData || null;

    if (action.payload?.token) {
      localStorage.setItem("token", action.payload.token)
    }

    if (callback) callback(action, state);
  }

// Универсальный редюсер для fulfilled имеющих Data:

export const onFulfilledDataReducer = <
  S extends StateType, // тип черновика стора (StateDraft)
  R extends IResponseSuccess<D>, // интерфейс ответа
  D // тип данных ответа, если массив - явно указать
>(key: keyof S, callback?: (action: PayloadAction<any>) => void) => (state: S, action: PayloadAction<R>) => {
  const { Success, Data } = action.payload;
  if (Success && Data) {
    state[key].result = Data;
    state[key].fetching = false;
    state[key].success = true;

    if (callback) callback(action);
  }

  if (typeof action.payload === "string") {
    state[key].result = action.payload;
    state[key].fetching = false;
    state[key].success = true;

    if (callback) callback(action);
  }
}
