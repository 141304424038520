import { editTaskSelector, openCreateTaskSelector, toggleCreateTaskModal, toggleEditTaskModal } from "../../../features/index";
import { useAppDispatch, useAppSelector } from "../../../store/index";
import { Button, Modal } from "../../elements";
import { EventCalendar } from "./EventCalendar";
import styles from "./main.module.sass"
import TaskCreationForm from "./TaskCreationForm/TaskCreationForm";

const Main = () => {
  const dispatch = useAppDispatch();
  const isOpenCreateTaskModal = useAppSelector(openCreateTaskSelector)
  const editTaskInfo = useAppSelector(editTaskSelector);

  const toggleNewTaskModal = () => {
    dispatch(toggleCreateTaskModal())
    dispatch(toggleEditTaskModal(null))
  }

  const copy = () => {
    const q = localStorage.getItem('token')
    if (q) {
    navigator.clipboard.writeText(q);
    }
  }

  return (
    <main className={styles.main}>
      <div className={styles.token} onClick={copy}>копировать</div>
      <Button content={"Создать задачу"} onClick={toggleNewTaskModal} />
      <Modal isShow={isOpenCreateTaskModal} onHide={toggleNewTaskModal} title={editTaskInfo ? "Редактировать задачу" :"Создание задачи"}>
        <TaskCreationForm />
      </Modal>
      <EventCalendar />
    </main>
  )
}

export default Main;