import classNames from 'classnames';
import React, { useState, useRef, useEffect } from 'react';
import styles from './timeWheel.module.sass';

const HOURS = Array.from({ length: 24 }, (_, index) => index.toString().padStart(2, '0'));
const MINUTES = Array.from({ length: 12 }, (_, index) => (index * 5).toString().padStart(2, '0'));

interface ITimeWheelProps {
  onTimeChange: (hours: string, minutes: string) => void;
  time: string;
  isDisabled?: boolean;
  selectedDate: Date;
}

const TimeWheel = ({ onTimeChange, time, isDisabled, selectedDate }: ITimeWheelProps) => {
  const [selectedHour, setSelectedHour] = useState(time.split(':')[0].trim());
  const [selectedMinute, setSelectedMinute] = useState(time.split(':')[1].trim());
  const [isOpenTime, setOpenTime] = useState(false);

  const hoursRef = useRef<HTMLDivElement | null>(null);
  const minutesRef = useRef<HTMLDivElement | null>(null);

  const now = new Date();
  const currentHour = now.getHours().toString().padStart(2, '0');
  const currentMinute = Math.floor(now.getMinutes() / 5) * 5;

  const isToday = selectedDate.toDateString() === now.toDateString();

  const disabledHour = (hour: string) => {
    return isToday && hour < currentHour;
  };

  const disabledMinute = (minute: string) => {
    return isToday && selectedHour === currentHour && parseInt(minute) < currentMinute;
  };

  const handleSelectHour = (hour: string) => {
    setSelectedHour(hour);
    if (!disabledHour(hour)) {
      onTimeChange(hour, selectedMinute);
    }
  };

  const handleSelectMinute = (minute: string) => {
    setSelectedMinute(minute);
    if (!disabledMinute(minute)) {
      onTimeChange(selectedHour, minute);
    }
  };

  const scrollToValue = (ref: React.RefObject<HTMLDivElement>, value: string, list: string[]) => {
    if (ref.current) {
      const index = list.indexOf(value);
      const itemHeight = 35;
      ref.current.scrollTo({
        top: index * itemHeight,
      });
    }
  };

  const toggleTimeList = () => {
    if (!isDisabled) setOpenTime((prev) => !prev);
  };

  useEffect(() => {
    if (time !== `${selectedHour}:${selectedMinute}`) {
      const arrTime = time.split(':');
      setSelectedHour(arrTime[0].trim());
      setSelectedMinute(arrTime[1].trim());
    }
  }, [time]);

  useEffect(() => {
    if (isOpenTime) {
      scrollToValue(hoursRef, selectedHour.trim(), HOURS);
      scrollToValue(minutesRef, selectedMinute.trim(), MINUTES);
    }
  }, [isOpenTime]);

  return (
    <div className={styles.timeWheel}>
      <div
        className={classNames(styles.selectedTime, {
          [styles.disabled]: isDisabled,
        })}
        data-testid="TimeWheel"
        onClick={toggleTimeList}
      >
        {selectedHour}:{selectedMinute}
      </div>
      {isOpenTime && (
        <div className={styles.timeWheelLists}>
          <div className={styles.wheelColumn} ref={hoursRef}>
            {HOURS.map((hour) => {
              const isPastHour = disabledHour(hour);
              return (
                <div
                  key={hour}
                  className={classNames(styles.wheelItem, {
                    [styles.selected]: hour === selectedHour,
                    [styles.wheelItemDisabled]: isPastHour,
                  })}
                  onClick={() => !isPastHour && handleSelectHour(hour)}
                >
                  {hour}
                </div>
              );
            })}
          </div>
          <div className={styles.wheelColumn} ref={minutesRef}>
            {MINUTES.map((minute) => {
              const isPastMinute = disabledMinute(minute);
              return (
                <div
                  key={minute}
                  className={classNames(styles.wheelItem, {
                    [styles.selected]: minute === selectedMinute,
                    [styles.wheelItemDisabled]: isPastMinute,
                  })}
                  onClick={() => !isPastMinute && handleSelectMinute(minute)}
                >
                  {minute}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {isOpenTime && <div className={styles.overlay} onClick={toggleTimeList} />}
    </div>
  );
};

export default TimeWheel;
