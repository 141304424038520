
export enum Reducers {
  planner = "plannerData",
  ui = "uiData"
}

export type TUser = {
  telegramId: number;
  username?: string;
  firstName: string;
  lastName?: string;
}

export type TTask = {
  user?: number;
  taskName: string,
  dateStart: number,
  dateEnd: number,
  comment?: string,
  reminderTime?: number,
  isOpen?: boolean,
  id: string,
}

export type TLoginInfo = {
  token: string;
  user: TUser;
}

export type TSnack = {
  id: string;
  text: string
  variant?: 'success' | 'warning' | 'error' | 'info';
}