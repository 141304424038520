import { add } from "date-fns";
import * as Yup from "yup";

export const initialValuesForm = {
  taskName: '',
  dateStart: new Date(),
  dateEnd: add(new Date(), { hours: 1 }),
  comment: "",
  reminderTime: 300000
}

export const validationSchema = Yup.object().shape({
  taskName: Yup.string().required("Обязательно к заполнению"),
});

export const reminderTimeOptions = [
  { label: "5 мин.", value: 300000 },
  { label: "15 мин.", value: 900000 },
  { label: "30 мин.", value: 1800000 },
  { label: "45 мин.", value: 2700000 },
  { label: "1 час", value: 3600000 },
  { label: "2 часа", value: 7200000 },
  { label: "3 часа", value: 10800000 },
  { label: "1 день", value: 86400000 },
]