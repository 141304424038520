import classNames from "classnames";
import { FC } from "react";
import styles from "./button.module.sass"

interface IButtonProps {
  content: string | JSX.Element;
  additionalClassName?: string;
}

const Button: FC<IButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({ content, additionalClassName, ...props}) => {
  return (
    <button
      className={classNames(styles.button, additionalClassName && additionalClassName)}
      {...props}
    >
      {content}
    </button>
  )
}

export default Button;