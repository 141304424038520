import { Snackbar } from "components/elements/Snackbar"
import { snacks } from "features";
import { useAppSelector } from "store";

const Snacks = () => {
  const snacksData = useAppSelector(snacks)
  return (
    <>
      {snacksData.map((snack, i) => (
        <Snackbar key={snack.id} {...snack} style={{transform: `translateY(${i * 45}px)`}} />
      ))}
    </>
  )
}

export default Snacks;