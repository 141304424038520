import classNames from "classnames";
import { useState } from "react";
import arrowIcon from "./assets/arrow-icon.svg"
import styles from "./select.module.sass";

interface ISelectProps {
  value: string;
  options: { label: string, value: string | number }[];
  onChange: (value: string | number) => void;
  classNameContainer?: string;
  isDisabled?: boolean;
}

const Select = ({ value, options, onChange, classNameContainer, isDisabled }: ISelectProps) => {
  const [isOpen, setOpen] = useState(false);

  const handleSelected = (option: string | number) => {
    setOpen(false);
    onChange(option)
  }

  return (
    <div className={classNames(styles.selectContainer, classNameContainer && classNameContainer)}>
      <div className={classNames(styles.select, {
        [styles.disabled]: isDisabled,
      })} onClick={() => {
        if (!isDisabled) setOpen((prev) => !prev)
      }}>
        <span className={styles.selectedText}>{value}</span>
        <img src={arrowIcon} alt={"Открыть список"} width={24} height={24} />
      </div>
      {isOpen &&
        <div className={styles.selectList}>
          {options.map(({ label, value }) => (
            <div className={styles.selectOption} onClick={() => handleSelected(value)} key={label + value}>{label}</div>
          ))}
        </div>
      }
    </div>
  )
}

export default Select