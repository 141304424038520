import crossIcon from './assets/clear.svg';
import doneIcon from './assets/snack-success-icon.svg';
import errorIcon from './assets/snack-error-icon.svg';
import infoIcon from './assets/snack-info-icon.svg';
import warningIcon from './assets/snack-warning-icon.svg';
import classNames from 'classnames';
import { type CSSProperties, useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useAppDispatch } from 'store';
import { removeSnack } from 'features';
import styles from './snackbar.module.sass';

export type SnackbarVariants = 'success' | 'warning' | 'error' | 'info';

export interface SnackbarProps {
  isOpen?: boolean;
  id: string;
  text: string;
  variant?: SnackbarVariants;
  autoCloseTime?: number;
  style?: CSSProperties;
}

const Snackbar = ({
  isOpen = true,
  id,
  text,
  variant = 'success',
  autoCloseTime = 3000,
  style,
}: SnackbarProps) => {
  const dispatch = useAppDispatch();
  const timerIdRef = useRef<NodeJS.Timeout>();
  const aniIdRef = useRef<NodeJS.Timeout>();


  const [beforeClose, setBeforeClose] = useState(false);
  const [isOpened, setIsOpened] = useState(isOpen);
  const snackIcon =
    variant === 'warning' ? (
      warningIcon
    ) : variant === 'error' ? (
      errorIcon
    ) : variant === 'info' ? (
      infoIcon
    ) : (
      doneIcon
    );
  const cn = classNames(styles.snackbar, styles[variant], beforeClose && styles.beforeClose);

  const close = () => {
    dispatch(removeSnack(id));
    setIsOpened(false );
  };

  useEffect(() => {
    if (!isOpen) return undefined;
    timerIdRef.current = setTimeout(() => close(), autoCloseTime);
    aniIdRef.current = setTimeout(() => setBeforeClose(true), autoCloseTime - 300);
    return () => {
      setBeforeClose(false);
      if (timerIdRef.current && aniIdRef.current)
      clearTimeout(timerIdRef.current);
      clearTimeout(aniIdRef.current);
    };
  }, [isOpen, isOpened]);

  if (isOpened) {
    return createPortal(
      <div className={cn} style={style} data-testid="snackbar">
        <img src={snackIcon} className={styles.variantIcon} alt={variant} />
        {text}
        <img src={crossIcon} className={styles.crossIcon} onClick={close} alt={"Close"} />
      </div>,
      document.getElementById('root')!,
    );
  }
  return null;
};

export default Snackbar;
