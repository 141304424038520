import { Route, Routes } from "react-router-dom";
import { Header } from "../components";

import { routes } from "./RouterList";

import 'bootstrap/dist/css/bootstrap.min.css';

const Router = () => {
  return (
    <>
      <Header />
      <Routes>
        {routes.map(({ path, Component }) => (
          <Route
            path={path}
            key={path}
            element={
              // <Suspense fallback={<PageSkeleton />}>
              <Component />
              // </Suspense>
            }
          />
        ))}
        {/* <Route path="*" element={<Navigate to="/404" replace />} /> */}
      </Routes>
    </>

  );
};

export default Router;
