import { Form } from "react-bootstrap";
import styles from "./switch.module.sass"

interface ISwitchProps {
  label: string;
  onChange: (value: boolean) => void;
  isChecked: boolean;
  isDisabled?: boolean;
  name?: string;
  id?: string;
}

const Switch = ({ label, onChange, isDisabled, isChecked, name, id }: ISwitchProps) => {
  return (
    <Form.Check
      type="switch"
      label={label}
      className={styles.switch}
      onChange={(e) => onChange(e.target.checked)}
      disabled={isDisabled}
      checked={isChecked}
      name={name}
      id={id}
    />
  )
}

export default Switch;