import { useEffect, useRef, useState } from "react";
import Calendar from '@event-calendar/core';
import ListPlugin from '@event-calendar/list';
import TimePlugin from '@event-calendar/time-grid';
import SwitchSelector from "react-switch-selector";
import '@event-calendar/core/index.css';
import "./eventCalendar.sass";
import { useAppSelector } from "../../../../store/index";
import { tasksSelector } from "../../../../features/index";
import SettingsTask from "./SettingsTask/SettingsTask";
import { TTask } from "types/types";
import { Button } from "components/elements";

type TView = 'timeGridDay' | 'listWeek'

const options = [
  {
    label: "День",
    value: "timeGridDay",
  },
  {
    label: "Неделя",
    value: "listWeek",
  }
];

const defaultPosition = { left: 0, top: 0 }

const EventCalendar = () => {
  const calendarRef = useRef<HTMLDivElement>(null);
  const [currentView, setCurrentView] = useState<TView>('timeGridDay');
  const { result: tasks } = useAppSelector(tasksSelector)
  const [activeTask, setActiveTask] = useState<TTask | null>(null);
  const [settingsPosition, setSettingsPosition] = useState(defaultPosition)
  const [showFullDay, setShowFullDay] = useState(false); // Управление состоянием отображения полной сетки дня

  useEffect(() => {
    document.body.style.overflow = settingsPosition.left ? 'hidden' : '';

    return () => {
      document.body.style.overflow = ''
    }
  }, [settingsPosition.left])

  useEffect(() => {
    if (calendarRef.current) {
      const now = new Date();
      const startHour = showFullDay ? "00:00" : `${String(now.getHours()).padStart(2, '0')}:00`; // Начальное время (полный день или от текущего часа)
      const calendar = new Calendar({
        target: calendarRef.current,
        props: {
          plugins: [ListPlugin, TimePlugin],
          options: {
            events: tasks ? tasks?.map((task, index) => ({
              title: {
                html:
                  `
                  <div class='taskInfo ${task.isOpen ? '' : 'taskClosed'}' id=${task.id}>
                  ${task.taskName}
                  ${!!task.comment ? `<p class='taskComment'>${task.comment}</p>` : ''}
                  </div>
                    `
              },
              start: new Date(task.dateStart),
              end: new Date(task.dateEnd),
              id: index,
            })) : [],

            view: currentView,
            slotMinTime: startHour,
            eventClick: (info) => {
              if (tasks) {
                setActiveTask(tasks[+info.event.id])
                //@ts-ignore
                setSettingsPosition({ left: 82, top: info.jsEvent.pageY + 25 })
              }
            },
            locale: 'ru',
            buttonText: {
              today: "Сегодня",
            },
            slotDuration: '00:15',
            noEventsContent: "Нет задач",
            slotHeight: 70,
          }
        }
      });

      return () => {
        calendar.destroy();
      };
    }
  }, [currentView, tasks, showFullDay]);

  const onChange = (newValue: TView) => {
    setCurrentView(newValue);
  };

  const toggleFullDayView = () => setShowFullDay((prev) => !prev); // Переключение состояния

  return (
    <div>
      <div className={"switchContainer"}>
        <SwitchSelector
          onChange={(value) => onChange(value as TView)}
          options={options}
          backgroundColor={window.Telegram.WebApp.themeParams?.section_separator_color}
          fontColor={"#333"}
          selectedBackgroundColor={window.Telegram.WebApp.themeParams?.button_color}
          selectedFontColor={window.Telegram.WebApp.themeParams?.button_text_color}
          wrapperBorderRadius={6}
          optionBorderRadius={6}
          selectionIndicatorMargin={2}
        />
      </div>
      {currentView === "timeGridDay" && (
        <div className="fullDayToggleContainer">
          <Button onClick={toggleFullDayView} content={showFullDay ? "Показать с текущего времени" : "Показать полный день"} />
        </div>
      )}
      <div ref={calendarRef} />
      {!!settingsPosition.left && <SettingsTask position={settingsPosition} resetPosition={() => setSettingsPosition(defaultPosition)} activeTask={activeTask} />}

    </div>
  );
};

export default EventCalendar;
